var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import { CanvasEvents } from '../shared/fabric/fabric.events.js';
export const ControlTypes = {
    circle: 'circle',
    rect: 'rect',
    objectToolbar: 'objectToolbar',
};
/**
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(src) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    });
}
/**
 * @param {SFabric} fabric
 * @param {string} src
 */
function loadAndCacheSvg(fabric, src) {
    loadImage(src).then((/** @type {HTMLImageElement} */ img) => {
        fabric.SVGCache[src] = img;
    });
}
/**
 * @param {{
 *  ctx: CanvasRenderingContext2D,
 *  left: number,
 *  top: number,
 *  sizeX: number,
 *  sizeY: number,
 *  styleOverride: any,
 *  fabricObject: SFabricObj,
 *  img: HTMLImageElement
 * }} param0
 */
function renderToolbarItem({ ctx, left, sizeX, sizeY, top, styleOverride, fabricObject, img }) {
    const cornerSize = styleOverride.cornerSize || fabricObject.cornerSize;
    const xSize = sizeX || cornerSize;
    const ySize = sizeY || cornerSize;
    const xSizeBy2 = xSize / 2;
    const ySizeBy2 = ySize / 2;
    ctx.drawImage(img, left - xSizeBy2, top - ySizeBy2, xSize, ySize);
}
/**
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} left
 * @param {number} sizeX
 * @param {number} sizeY
 * @param {number} top
 * @param {string} position
 * @param {number} nControls */
function renderControlCard(ctx, left, top, position, numberOfActions) {
    // named as "skinny side" because it can be the width or height depending on position of the bar
    const controlCardSkinnySideSize = 48;
    const borderRadius = 16;
    const bgColor = '#f7f6f2';
    const cardOutlineColor = '#4a4843';
    const separatorColor = '#aba69a';
    // NOTE: There is some stuff in these IF statements that could be DRY'd but it felt clearer keeping things separate
    if (position == 'right' || position == 'left') {
        var sizeX = controlCardSkinnySideSize;
        var sizeY = numberOfActions * controlCardSkinnySideSize + (controlCardSkinnySideSize / 2) - 7; // 7 is a magic number that just makes it look better
        // these two assignments move the control bar up and to the left so it properly centers it respective with icons
        left = left - sizeX / 2; // centers it 
        top = top - controlCardSkinnySideSize / 2; // centers it
        ctx.save();
        ctx.translate(left, top); // we can operate within normal 0,0 coordinates instead of left/top after applying the translate
        // ===========================================
        //  Draw the surround box around all the icons
        // ===========================================
        ctx.beginPath();
        ctx.moveTo(borderRadius, 0);
        // top right corner
        ctx.lineTo(sizeX - borderRadius, 0);
        ctx.arcTo(sizeX, 0, sizeX, borderRadius, borderRadius);
        //bottom right corner
        ctx.lineTo(sizeX, sizeY - borderRadius);
        ctx.arcTo(sizeX, sizeY, sizeX - borderRadius, sizeY, borderRadius);
        // bottom left corner
        ctx.lineTo(borderRadius, sizeY);
        ctx.arcTo(0, sizeY, 0, sizeY - borderRadius, borderRadius);
        // top left corner
        ctx.lineTo(0, borderRadius);
        ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
        ctx.fillStyle = bgColor;
        ctx.fill();
        ctx.strokeStyle = cardOutlineColor;
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
        // ===========================================
        // ===========================================
        // ===========================================
        //  Draw the the separators
        //   NOTE: This depends on there being 8 quick actions. This will need changed if/when we add more of them.
        // ===========================================
        ctx.beginPath();
        var xDistanceFromEdge = 7;
        var startingLocationY = controlCardSkinnySideSize + 2;
        ctx.moveTo(xDistanceFromEdge, startingLocationY);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 2);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 2);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 3);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 3);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 4);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 4);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 5);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 5);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 6);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 6);
        ctx.moveTo(xDistanceFromEdge, startingLocationY * 7);
        ctx.lineTo(sizeX - xDistanceFromEdge, startingLocationY * 7);
        ctx.strokeStyle = separatorColor;
        ctx.lineWidth = 1;
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
    }
    if (position == 'top' || position == 'bottom') {
        var sizeX = numberOfActions * controlCardSkinnySideSize + (controlCardSkinnySideSize / 2) - 9;
        var sizeY = controlCardSkinnySideSize;
        // these two assignments move the control bar up and to the left so it properly centers it respective with icons
        left = left - controlCardSkinnySideSize / 2; // centers it 
        top = top - controlCardSkinnySideSize / 2; // centers it
        ctx.save();
        ctx.translate(left, top); // we can operate within normal 0,0 coordinates instead of left/top after applying the translate
        // ===========================================
        //  Draw the surround box around all the icons
        // ===========================================
        ctx.beginPath();
        ctx.moveTo(borderRadius, 0);
        // top right corner
        ctx.lineTo(sizeX - borderRadius, 0);
        ctx.arcTo(sizeX, 0, sizeX, borderRadius, borderRadius);
        //bottom right corner
        ctx.lineTo(sizeX, sizeY - borderRadius);
        ctx.arcTo(sizeX, sizeY, sizeX - borderRadius, sizeY, borderRadius);
        // // bottom left corner
        ctx.lineTo(borderRadius, sizeY);
        ctx.arcTo(0, sizeY, 0, sizeY - borderRadius, borderRadius);
        // top left corner
        ctx.lineTo(0, borderRadius);
        ctx.arcTo(0, 0, borderRadius, 0, borderRadius);
        ctx.fillStyle = bgColor;
        ctx.fill();
        ctx.strokeStyle = cardOutlineColor;
        ctx.lineWidth = 2;
        ctx.stroke();
        ctx.closePath();
        // ===========================================
        // ===========================================
        // ===========================================
        //  Draw the the separators
        //   NOTE: This depends on there being 8 quick actions. This will need changed if/when we add more of them.
        // ===========================================
        ctx.beginPath();
        var yDistanceFromEdge = 7;
        var startingLocationX = controlCardSkinnySideSize + 2;
        ctx.moveTo(startingLocationX, yDistanceFromEdge);
        ctx.lineTo(startingLocationX, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 2, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 2, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 3, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 3, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 4, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 4, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 5, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 5, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 6, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 6, sizeY - yDistanceFromEdge);
        ctx.moveTo(startingLocationX * 7, yDistanceFromEdge);
        ctx.lineTo(startingLocationX * 7, sizeY - yDistanceFromEdge);
        ctx.strokeStyle = separatorColor;
        ctx.lineWidth = 1;
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
    }
}
/** @param {Object<string, Control>} objectControls */
export function createCustomControls(objectControls) {
    function getControlPosition(dim, finalMatrix, fabricObject, controlIndex) {
        var _a, _b;
        const objectLeftTop = fabric.Object.prototype._getLeftTopCoords.call(fabricObject);
        const objectRightTop = {
            x: objectLeftTop.x + fabricObject.width + 32,
            y: objectLeftTop.y + 32,
        };
        const objectRightBottom = {
            x: objectLeftTop.x + fabricObject.width,
            y: objectLeftTop.y + fabricObject.height,
        };
        const objectLeftBottom = {
            x: objectLeftTop.x,
            y: objectLeftTop.y + fabricObject.height,
        };
        const canvasWidth = fabricObject.canvas && fabricObject.canvas.width ? fabricObject.canvas.width : 800;
        const canvasHeight = fabricObject.canvas && fabricObject.canvas.height ? fabricObject.canvas.height : 400;
        const position = ((_b = (_a = fabricObject === null || fabricObject === void 0 ? void 0 : fabricObject.smartboard) === null || _a === void 0 ? void 0 : _a.quickAction) === null || _b === void 0 ? void 0 : _b.position) || 'right';
        const bounds = fabricObject.getBoundingRect();
        // The amount of space each control takes up
        const controlSpace = 50;
        // This point is used to keep the quick controls locked in space as the selected object rotates
        let rightPoint = bounds.width / 2 + 70;
        let offsetAmt = -150 + (controlIndex * controlSpace);
        let xOffset = offsetAmt;
        let yOffset = 0;
        if (position == 'left') {
            rightPoint = -bounds.width / 2 - 70;
            if (objectLeftTop.y < 48) {
                xOffset = offsetAmt + Math.abs(objectLeftTop.y) + 32;
            }
            else if (objectLeftBottom.y * 2 > canvasHeight) {
                xOffset = offsetAmt - 320;
            }
            else {
                xOffset = offsetAmt;
            }
        }
        if (position == 'bottom') {
            if (objectLeftTop.x < 48) {
                rightPoint = Math.abs(objectLeftTop.x) + 64;
            }
            else if (objectRightTop.x > canvasWidth - 320) {
                rightPoint = objectRightTop.x - canvasWidth + 64;
            }
            else {
                rightPoint = 0;
            }
            xOffset = bounds.height / 2 + 70;
            yOffset = offsetAmt;
        }
        if (position == 'top') {
            if (objectLeftBottom.x < 48) {
                rightPoint = Math.abs(objectLeftTop.x) + 64;
            }
            else if (objectRightBottom.x > canvasWidth - 320) {
                rightPoint = objectRightTop.x - canvasWidth + 64;
            }
            else {
                rightPoint = 0;
            }
            xOffset = -bounds.height / 2 - 64;
            yOffset = offsetAmt;
        }
        let point = fabric.util.transformPoint({ x: this.x * dim.x + this.offsetX, y: this.y * dim.y + this.offsetY }, [
            0,
            0,
            0,
            0,
            finalMatrix[4] + rightPoint + yOffset, // x point
            finalMatrix[5] + xOffset, // y point
        ]);
        return point;
    }
    const controls = [
        {
            name: 'lock',
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/UnlockAction.svg',
            alternateSvgPaths: ['./design/svg/LockAction.svg'],
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.TOGGLE_LOCK, { bubbles: true, composed: true })),
            /** @type {QuickActionRender} */
            beforeRender: function ({ fabricObject }) {
                this.svgPath = fabricObject.lockMovementX ? './design/svg/UnlockAction.svg' : './design/svg/LockAction.svg';
            },
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 0);
            },
        },
        {
            name: 'moveUp',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.BRING_SELECTED_FORWARD, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/forward.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 1);
            },
        },
        {
            name: 'moveBack',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.SEND_SELECTED_BACKWARDS, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/backward.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 2);
            },
        },
        {
            name: 'copy',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.DUPLICATE_SELECTED, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/duplicate.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 3);
            },
        },
        {
            name: 'bigger',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.MAKE_BIGGER, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/bigger.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 4);
            },
        },
        {
            name: 'smaller',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.MAKE_SMALLER, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/smaller.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 5);
            },
        },
        {
            name: 'link',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.ADD_LINK_TO_ITEM, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/Link.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 6);
            },
        },
        {
            name: 'trash',
            whenClicked: () => window.dispatchEvent(new CustomEvent(CanvasEvents.REMOVE_SELECTED, { bubbles: true, composed: true })),
            actionName: ControlTypes.objectToolbar,
            svgPath: './design/svg/trash.svg',
            positionHandler: function (dim, finalMatrix, fabricObject /*, fabricObject, currentControl */) {
                return getControlPosition.call(this, dim, finalMatrix, fabricObject, 7);
            },
        },
    ];
    // Initializing the moving variable as false
    let moving = false;
    controls.forEach((c, i) => {
        let controlDefaults = {
            x: 0.5,
            y: 0,
            zIndex: 1,
            sizeX: 46, // sizeX/Y controls the clickable region for the icon, not the actual size of the icon
            sizeY: 46, // 46 is a magic number that works for the icons in the container
            offsetX: 32,
            cursorStyle: 'pointer',
        };
        c = Object.assign(Object.assign({}, controlDefaults), c);
        // const controlOffset = i * 47 - 120
        // let offsetY = controlOffset
        objectControls[c.name] = new window.fabric.Control(Object.assign(Object.assign({}, c), { mouseUpHandler: () => {
                c.whenClicked();
            }, 
            // offsetY, // Use the adjusted offsetY
            render: function (ctx, left, top, styleOverride, fabricObject) {
                var _a, _b;
                // for any custom actions before shared render
                c.beforeRender && c.beforeRender.call(this, { ctx, left, top, styleOverride, fabricObject });
                this.toolbarPosition = ((_b = (_a = fabricObject === null || fabricObject === void 0 ? void 0 : fabricObject.smartboard) === null || _a === void 0 ? void 0 : _a.quickAction) === null || _b === void 0 ? void 0 : _b.position) || 'right';
                if (i === 0)
                    renderControlCard(ctx, left, top, this.toolbarPosition, controls.length);
                renderToolbarItem({
                    ctx,
                    left,
                    top,
                    sizeX: 32, //actual size of the toolbar icon to be displayed
                    sizeY: 32,
                    styleOverride,
                    fabricObject,
                    img: fabric.SVGCache[this.svgPath],
                });
            } }));
    });
    // Load any svg paths not already cached
    Object.values(objectControls)
        .reduce((paths, { svgPath, alternateSvgPaths }) => {
        svgPath && !fabric.SVGCache[svgPath] && paths.push(svgPath);
        alternateSvgPaths && paths.push(...alternateSvgPaths);
        return paths;
    }, [])
        .forEach((svgPath) => __awaiter(this, void 0, void 0, function* () {
        try {
            if (!fabric.SVGCache[svgPath])
                loadAndCacheSvg(fabric, svgPath);
        }
        catch (error) {
            console.log(`fabric.Control.js createCustomControls error loading svg: ${svgPath}`, error);
        }
    }));
    fabric.Control.prototype.render = controlRenderOverride;
    return objectControls;
}
/**
 *
 * @param {CanvasRenderingContext2D} ctx
 * @param {number} left
 * @param {number} top
 * @param {*} styleOverride
 * @param {SFabricObj} fabricObject
 */
function controlRenderOverride(ctx, left, top, styleOverride, fabricObject) {
    const type = ControlTypes[this.actionName] ? this.actionName : styleOverride.cornerStyle || fabricObject.cornerStyle;
    const method = {
        [ControlTypes.circle]: window.fabric.controlsUtils.renderCircleControl,
        [ControlTypes.rect]: window.fabric.controlsUtils.renderSquareControl,
        [ControlTypes.objectToolbar]: renderObjectToolbarControl,
    }[type];
    method && method.call(this, ctx, left, top, styleOverride, fabricObject);
}
/**
 * Render a square control, as per fabric features, with a white background.
 * This function is written to respect object properties like transparentCorners, cornerSize
 * cornerColor, cornerStrokeColor
 * plus the addition of offsetY and offsetX.
 * @param {CanvasRenderingContext2D} ctx context to render on
 * @param {Number} left x coordinate where the control center should be
 * @param {Number} top y coordinate where the control center should be
 * @param {Object} styleOverride override for fabric.Object controls style
 * @param {fabric.Object} fabricObject the fabric object for which we are rendering controls
 */
export function renderObjectToolbarControl(ctx, left, top, styleOverride = {}, fabricObject) {
    const cornerSize = styleOverride.cornerSize || fabricObject.cornerSize;
    const xSize = this.sizeX || cornerSize;
    const ySize = this.sizeY || cornerSize;
    const xSizeBy2 = xSize / 2;
    const ySizeBy2 = ySize / 2;
    ctx.drawImage(fabric.SVGCache[this.svgPath], left - xSizeBy2, top - ySizeBy2, xSize, ySize);
}
