import { LitElement, css, html } from 'lit';
import { go } from '../router/router-mixin.js';
import { getSessionUser } from '../util/session.util.js';
import { routes } from '../router/routes.js';
import AppEvents from '../../app-events.js';
class AppHeader extends LitElement {
    constructor() {
        super();
        this.currentLocation = window.location.pathname;
    }
    connectedCallback() {
        super.connectedCallback();
        window.addEventListener(AppEvents.ROUTE_CHANGE, (/** @type {CustomEvent} */ e) => {
            this.currentLocation = window.location.pathname;
        });
    }
    /**
     * @param {string} location
     */
    routeCheck(location) {
        if (this.currentLocation === location) {
            return 'selected';
        }
        else {
            return '';
        }
    }
    render() {
        const menuItem = [
            { label: 'Decks', path: routes.DECKS.path, location: '/decks' },
            { label: 'Groups', path: routes.GROUPS.path, location: '/groups' },
            { label: 'Profile', path: routes.PROFILE.path, location: '/profile' },
        ];
        //   <div class="menu-items">
        //   ${menuItem.map(
        //     (item) =>
        //       html`<h2 class="${this.routeCheck(item.location)}" @click=${() => go(String(item.path))}>
        //         ${item.label}
        //       </h2>`
        //   )}
        // </div>
        return html `
      <div class="container">
        <div class="brand-name"><h1>SmartBoard</h1></div>

      </div>
    `;
    }
}
AppHeader.properties = {
    routes: { type: Object },
    currentLocation: { type: String },
    selected: { type: String },
};
AppHeader.styles = css `
    :host {
      display: block;
      font-family: 'Lato', sans-serif;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
    }
    h1 {
      color: var(--app-black);
    }
    .container {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      font-family: var(--primary-font-family);
      font-weight: 700;
      background-color: #BEBEBE;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }
    .brand-name {
    }
    .menu-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;
      color: var(--app-grey);
      cursor: pointer;
    }

    .selected {
      color: var(--app-primary);
    }
  `;
export default AppHeader;
customElements.define('app-header', AppHeader);
