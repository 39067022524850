import { pathToRegexp } from 'path-to-regexp';
import { userIsAdmin, userIsLoggedIn } from './router-permissions.js';
/**
 * @param {string} path
 * @returns {string}
 * */
export function getComponentName(path) {
    const trimmedPath = path.startsWith('/') ? path.slice(1) : path;
    return `${trimmedPath.replace(/\//g, '-')}-container`;
}
/** @param {string} path */
export function getComponentPath(path) {
    return `${path}/${getComponentName(path)}`;
}
/**
 * Extracts the path from route or parent.
 * @param {Route|null} parent - The parent route object.
 * @param {Route} route - The route object to process.
 * @returns {string} - The processed path string.
 */
export function extractPath(parent, route) {
    return (parent ? `${parent.path}/${route.id}` : `/${route.id}`).toLowerCase().replace('_', '-');
}
/**
 * Processes a single route object and adds the pathToRegexp property.
 * @param {string} key - The key of the route object.
 * @param {Route} route - The route object to process.
 * @param {Route|null} parent - The parent route object.
 * @returns {Route} - The processed route object.
 */
export function processRoute(key, route, parent = null) {
    var _a, _b;
    route.id = route.id || key;
    const path = route.path || extractPath(parent, route);
    const componentName = route.componentName || getComponentName(path);
    const componentPath = route.componentPath || getComponentPath(path);
    // add any parent permissions to current permissions
    route.permissions = route.permissions || [];
    if (parent)
        route.permissions = [...parent.permissions, ...route.permissions];
    const processed = {
        id: route.id,
        path,
        parent,
        pathRegexp: pathToRegexp(path),
        componentPath,
        componentName,
        permissions: (_a = route.permissions) !== null && _a !== void 0 ? _a : [],
        children: route.children || {},
        showHeader: (_b = route.showHeader) !== null && _b !== void 0 ? _b : true,
    };
    // After parent is initialized, we can process the child routes
    processed.children = processRoutes(processed.children, processed);
    return processed;
}
/**
 * Processes the given route objects and adds the pathToRegexp property.
 * @param {Object<string, Route>} routes - The route objects to process.
 * @param {Route|null} parentRoute - The parent route object.
 * @returns {Object<string, Route>} - The processed route objects.
 */
function processRoutes(routes, parentRoute = null) {
    return Object.entries(routes).reduce((acc, [key, route]) => {
        acc[key] = processRoute(key, route, parentRoute);
        return acc;
    }, /** @type {Object<String, Route>} */ ({}));
}
/** @typedef {Object<string, Route>} RouteObjects */
export const routes = processRoutes({
    LOGIN: {
        isPublic: true,
        showHeader: false,
    },
    SIGNUP: {
        isPublic: true,
        showHeader: false,
    },
    DECKS: {
        permissions: [userIsLoggedIn],
    },
    CANVAS: {
        showHeader: false,
        permissions: [userIsLoggedIn],
    },
    CARDS: {
        showHeader: false,
    },
    FONTS: {},
    GROUP: {},
    GROUP_FOLDER: {},
    GROUPS: {},
    SANDBOX: {},
    TRASH: {},
    DEMO: {
        // make sure user is logged in. passes on to children
        permissions: [userIsLoggedIn],
        children: {
            CHILD: {
                // make sure user is also an admin
                permissions: [userIsAdmin],
            },
        },
    },
    UPLOAD: {},
    SETTINGS: {},
    PROFILE: {
        permissions: [userIsLoggedIn],
    },
});
