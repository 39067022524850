const sessionVariables = {
    USER: 'smartboard-app-user',
};
/** @param {SessionUser} user - Id of logged in user */
export function setSessionUser(user) {
    return window.sessionStorage.setItem(sessionVariables.USER, JSON.stringify(user));
}
/** @returns {SessionUser} - Id of logged in user */
export function getSessionUser() {
    return JSON.parse(window.sessionStorage.getItem(sessionVariables.USER));
}
export function deleteSessionUser() {
    window.sessionStorage.removeItem(sessionVariables.USER);
}
