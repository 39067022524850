import { routes } from './routes.js';
/** @type {PermissionCheck} */
export function userIsLoggedIn(options) {
    var _a;
    return ((_a = options === null || options === void 0 ? void 0 : options.sessionUser) === null || _a === void 0 ? void 0 : _a.token) ? null : routes.LOGIN;
}
/** @type {PermissionCheck} */
export function userIsAdmin(options) {
    // TODO: get user permissions for admin from login data
    return true ? null : routes.LOGIN;
}
