import { html, css } from 'lit';
import RouterMixin from './shared/router/router-mixin.js';
import './shared/components/app-header.js';
import { fabric } from 'fabric';
import { wrapFabric } from './smartboard/wrapFabric.js';
wrapFabric(fabric);
class AppEnter extends RouterMixin {
    render() {
        // return html` ${this.showHeader ? html`<app-header></app-header>` : ''}
        return html `

      <slot></slot>`;
    }
}
AppEnter.styles = css `
    :host {
      -webkit-tap-highlight-color: transparent;
    }
  `;
customElements.define('app-start', AppEnter);
export default AppEnter;
